'use client';

import { PortableText } from '@portabletext/react';
import { useSmoothScrollTo } from 'ui/hooks';
import type { TranslationsDictionary } from '@lib/i18n';
import ProductsCarousel from '@lib/ui/components/products-carousel';

type Props = {
  data?: {
    anchor?: string;
    bgColor?: string;
    headline?: string;
    subheadline?: [];
    products: {
      _key: string;
      name?: string;
      shortDescription?: [];
      slug?: {
        current: string;
      };
      image?: {
        asset: {
          _type: string;
          _ref: string;
        };
        lqip: string;
      };
    }[];
    warning?: [];
  };
  translationsDictionary: TranslationsDictionary;
};

export default function FeaturedProducts({ data, translationsDictionary }: Props): JSX.Element | null {
  const bind = useSmoothScrollTo(`#${data?.anchor}`);

  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-12 sm:py-6 lg:py-20 ${
        data?.bgColor ? data.bgColor : 'bg-white'
      }`}
      id={data?.anchor}
      {...(data?.anchor ? { ...bind } : null)}
    >
      <div className="flex w-full justify-center px-4 md:px-10 lg:px-16">
        <div className="flex gap-x-8 flex-col justify-center items-center w-full max-w-screen-md">
          <h3 className="w-full pb-4 lg:pb-8 text-2xl lg:text-4xl text-primary font-bold leading-7 lg:leading-10 text-left md:text-center">
            {data?.headline}
          </h3>
          <div className="pb-8 text-[0.9375rem] md:text-lg leading-6 text-black text-left md:text-center">
            <PortableText value={data?.subheadline ?? []} />
          </div>
        </div>
      </div>
      <ProductsCarousel products={data?.products} translationsDictionary={translationsDictionary} />
      {data?.warning ? (
        <div className="flex w-full justify-center px-4 md:px-10 lg:px-16 py-6">
          <div className="text-xs leading-4 text-black text-center max-w-screen-md">{data.warning}</div>
        </div>
      ) : null}
    </section>
  );
}
