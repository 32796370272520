'use client';

import Link from 'next/link';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { PortableText } from '@portabletext/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { urlForImage } from '@lib/sanity/image';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';
import 'swiper/css';
import 'swiper/css/pagination';

type ProductsCarouselProps = {
  products?: {
    name?: string;
    shortDescription?: [];
    slug?: {
      current: string;
    };
    image?: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
  }[];
  translationsDictionary: TranslationsDictionary;
};

export default function ProductsCarousel({ products, translationsDictionary }: ProductsCarouselProps): JSX.Element {
  const t = useTranslation(translationsDictionary);

  if (!products || products.length === 0) {
    return <div className="hidden">No products have been associated to this component</div>;
  }

  return (
    <Swiper
      breakpoints={{
        520: {
          pagination: {
            enabled: products.length > 2,
          },
          slidesPerView: products.length >= 2 ? 2 : products.length,
          spaceBetween: 20,
        },
        1040: {
          centeredSlides: products.length <= 3,
          centeredSlidesBounds: products.length <= 3,
          pagination: {
            enabled: products.length > 2,
          },
          slidesPerView: products.length >= 3 ? 3 : products.length,
          spaceBetween: 30,
        },
        1300: {
          centeredSlides: products.length <= 3,
          centeredSlidesBounds: products.length <= 3,
          pagination: {
            enabled: products.length > 2,
          },
          slidesPerView: products.length >= 4 ? 4 : products.length,
          spaceBetween: 40,
        },
      }}
      className={`w-full ${products.length === 1 ? 'max-w-[560px]' : ''}${
        products.length === 2 ? 'max-w-[960px]' : ''
      }`}
      loop={false}
      modules={[Pagination]}
      pagination={{
        clickable: true,
      }}
      slidesPerView={products.length > 1 ? 1.2 : 1}
      spaceBetween={30}
    >
      {products.map((product) => (
        <SwiperSlide className="" key={product.name}>
          <Card className="w-full max-w-[30rem]" elevation={0}>
            <Link href={`/productos/${product.slug?.current}`}>
              <CardMedia
                className="h-[11rem] sm:h-[16rem] lg:h-[12rem]"
                image={product.image ? urlForImage(product.image).url() : ''}
                title={product.name}
              />
            </Link>
            <CardContent>
              <h4 className="pb-2 text-xl text-primary font-bold leading-6 line-clamp-3">
                <Link href={`/productos/${product.slug?.current}`}>{product.name}</Link>
              </h4>
              <div className="text-base text-black leading-5 line-clamp-6">
                <PortableText value={product.shortDescription ?? []} />
              </div>
            </CardContent>
            <CardActions>
              {/* TODO: the /productos/ path segment must be localized */}
              <Button
                color="secondary"
                disableElevation
                href={`/productos/${product.slug?.current}`}
                variant="contained"
              >
                {t('productsCarousel.cta')}
              </Button>
            </CardActions>
          </Card>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
